<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Are You Sure to Move File?<v-spacer></v-spacer>
          <v-icon  dense  size="35">mdi-folder-swap</v-icon>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="$emit('closedialog')">Cancle</v-btn>

          <v-btn color="green darken-1" text @click="$emit('movefile')">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog",],
};
</script>

<style>
</style>